:root{
  --ct1:#333;
  --ct2:#fff;
  --ct3:#26487f;
  --ct4:#005fa6;
  --ct5:#0084e7;

  --bg:white;
  --bg1:#f5f6fb;
  --bg2:#6c9fd6;
  --bg3:#e4903d;
  --bg4:#aae0ff;

  --f1 : 2vmin;
  --f2 : 3vmin;
  --f3 : 4vmin;

  --maxW : 160vmin;
  background-color:var(--bg1);
}





div{
  box-sizing: border-box;
}


h1{
  font-size: var(--f3);
  color:var(--ct3);
  text-align:center;
}

.mainLogo{
  position:relative;
  left:50%;
  transform:translateX(-50%);
  width:100%;
  max-width:var(--maxW);
}
.mainLogo img{
  position:relative;
  width:20vmin;
  left:50%;
  transform:translateX(-50%);

}

.section{
  position:relative;
  width:100%;
  height:auto;
}
.intro{
  position:relative;
  width:100%;
  min-height: 40vmin;
  max-width:var(--maxW);
}
.intro .body .content{
  position:relative;
  width:100%;
  padding:8vmin 4vmin;
  padding-right:40vmin;
  font-size: var(--f2);
  z-index:2;

}
.intro .body .icon{
  position:absolute;
  width:80vmin;
  height:100%;
  bottom:0vmin;
  right:4vmin;
}

.intro .body .icon img{
  position:absolute;
  max-width:100%;
  max-height:100%;
  right:0%;
  bottom:0px;

}




.note{
  position:relative;
  width:100%;
  min-height: 30vmin;
  max-width:var(--maxW);
}
.note .body .content{
  position:relative;
  width:100%;
  padding:8vmin 4vmin;
  padding-left:40vmin;
  font-size: var(--f2);
  z-index:2;
  color:var(--ct3);
  font-weight: 700;

}
.note .body .icon{
  position:absolute;
  width:80vmin;
  height:calc(100% + 5vmin);
  bottom:0vmin;
  left:4vmin;
}

.note .body .icon img{
  position:absolute;
  max-width:100%;
  max-height:100%;
  left:0%;
  bottom:0px;
}


.pList{
  max-width:var(--maxW);  
  position:relative;
  width:100%;
  font-size: var(--f1);
}
.pList .item{
    background-color:var(--bg);
    padding:4vmin;
    border-radius:2vmin;
    margin-bottom:4vmin;
}
.pList .item .cont{
  padding:0vmin 2vmin;
  padding-top:3vmin;
}
.pList .item .body > .name{
    font-size: var(--f2);
    font-weight: 700;
    color:var(--ct4);
}
.pList .item .body > .sub{
  font-size: var(--f1);
  font-style: italic;
}
.pList .item .body table {
    border:.1vmin solid var(--ct1);
    border-collapse: collapse;
}
.pList .item .body table td{
  border:.1vmin solid var(--ct1);
  padding:0vmin 2vmin;
}

.pList .item a{
  font-weight: 700;
  color:var(--ct5);
  text-decoration:unset;
}

.footer{
  text-align:center;
  padding:4vmin 2vmin;
  font-size: var(--f1);
  color:var(--ct2);
  font-weight: 500;
}
.footer .logo img{
    width:30vmin;
    height:auto;
}


.hcenter{
  left:50%;
  transform:translateX(-50%);
}


.bg1{
  background-color:var(--bg1);
}
.bg2{
  background-color:var(--bg2);
}
.bg3{
  background-color:var(--bg3);
}
.bg4{
  background-color:var(--bg4);
}
.mt1{
  margin-top:4vmin;
}
.mt2{
  margin-top:6vmin;
}


@media screen and (max-width:600px){
  :root{
  --f1 : 3.5vmin;
  --f2 : 4.5vmin;
  --f3 : 5.5vmin;
  }

  .mainLogo img{
    width:40vmin;
  }

  .intro .body .content{
    width:100%;
    padding-right:unset;
    background-color:rgba(255,255,255,.9);
  }
  .note .body .content{
    width:100%;
    padding-left:unset;
    padding:12vmin 4vmin;
  
  }
  .note .body .icon{
    opacity:0;
  }
}